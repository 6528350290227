import anime from 'animejs';
import { cancelConversionsFunction } from '../app';

export const servicesContextOut = (item) => {
  if ($('.services-page-wrap').length > 0) {
    console.log(servicesPageWrap);
    console.log('servicesContextOut');
    var servicesPageWrap = document.querySelector('.services-page-wrap');
  }
  // console.log(servicesPageWrap);
  if($(document).width() > 1024) {
    const servicesOutAnimations = anime.timeline({
      easing: 'linear',
    }).add({
      targets: item.querySelector('.services-page'),
      duration: 800,
      width: ['35vw', '100vw']
    }, 0).add({
      targets: item,
      duration: 300,
    }, 1200).add({
      targets: item.querySelector('.services-page__head'),
      duration: 800,
      translateX:  [0, -100],
      opacity: [1, 0],
    }, 600).add({
      targets: item.querySelector('.services-page__list3'),
      duration: 800,
      translateX:  [0, -150],
      opacity: [1, 0],
    }, 600).add({
      targets: item.querySelector('.services-page__list2'),
      duration: 800,
      translateX:  [0, -100],
      opacity: [1, 0],
    }, 300).add({
      targets: item.querySelector('.services-page__list1'),
      duration: 800,
      translateX:  [0, -50],
      opacity: [1, 0],
    }, 0).add({
      targets: servicesPageWrap,
      duration: 800,
      translateX:  [0, 150],
      opacity: [1, 0],
    }, 200)
    // .add({ // Анимация органов навигации
    //   targets: document.querySelector('a.logo--image'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [1, 0],
    //   translateX: [0, -100],
    // }, 400).add({
    //   targets: document.querySelector('a.logo--text'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [1, 0],
    //   translateX: [0, -100],
    // }, 400).add({
    //   targets: document.querySelector('.wrap-burger'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [1, 0],
    //   translateX: [0, 100],
    // }, 400)
    .add({
      targets: document.querySelector('.nav__next'),
      easing: 'easeInQuad',
      duration: 300,
      opacity: [1, 0],
      translateX: [0, 100],
    }, 400).add({
      targets: document.querySelector('.nav__prev'),
      easing: 'easeInQuad',
      duration: 300,
      opacity: [1, 0],
      translateX: [0, -100],
    }, 400).add({
      targets: document.querySelector('.services__dots'),
      easing: 'easeInQuad',
      duration: 300,
      opacity: [1, 0],
      translateX: [0, 100]
    }, 400);
  
    return servicesOutAnimations.finished;
  }else {
    const servicesOutAnimations = anime.timeline({
      easing: 'linear',
    }).add({
      targets: item.querySelector('.services-page'),
      duration: 800,
      width: ['35vw', '100vw'],
    }, 0).add({
      targets: item,
      duration: 300,
    }, 1200).add({
      targets: servicesPageWrap,
      duration: 800,
      translateX:  [0, 150],
      opacity: [1, 0],
    }, 200)
    // .add({ // Анимация органов навигации
    //   targets: document.querySelector('.wrap-burger'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [1, 0],
    //   translateX: [0, 100],
    // }, 400)
    .add({
      targets: document.querySelector('.services__arrow'),
      easing: 'easeInQuad',
      duration: 300,
      opacity: [1, 0],
      translateX: [0, -100]
    }, 400);

    return servicesOutAnimations.finished;
  }
};

export const servicesContextIn = (item) => {
  var qwer = document.querySelector('body');
  console.log('item', item);
  console.log('serviceIn');
  if($(document).width() > 1024) {
    const serviceInAnimations = anime.timeline({
      easing: 'linear',
    }).add({
      begin: cancelConversionsFunction(qwer),
      targets: item,
      duration: 300,
      // opacity: [0 , 1],
    }, 0).add({
      targets: item.querySelector('.services-page__head'),
      duration: 800,
      translateX:  [-100, 0],
      opacity:  [0, 1],
    }, 200) .add({
      targets: item.querySelector('.services-page__list1'),
      duration: 800,
      translateX: [50, 0],
      opacity:  [0, 1],
    }, 800).add({
      targets: item.querySelector('.services-page__list2'),
      duration: 800,
      translateX: [100, 0],
      opacity:  [0, 1],
    }, 1100).add({
      targets: item.querySelector('.services-page__list3'),
      duration: 800,
      translateX:  [150, 0],
      opacity:  [0 , 1],
    }, 1400)
    .add({  // Анимация органов навигации
      targets: document.querySelector('a.logo--image'), 
      easing: 'easeInQuad',
      duration: 300,
      opacity: [1, 1],
      // translateX: [-150, 0],
    }, 400).add({
      targets: document.querySelector('a.logo--text'),
      easing: 'easeInQuad',
      duration: 300,
      opacity: [1, 1],
      // translateX: [-150, 0],
    }, 400)
    // .add({
    //   targets: document.querySelector('.wrap-burger'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [0, 1],
    //   translateX: [100, 0],
    // }, 400)
    ;
  
    return serviceInAnimations.finished;
  } else {
    const serviceInAnimations = anime.timeline({
      easing: 'linear',
    }).add({
      begin: cancelConversionsFunction(qwer),
      targets: item,
      duration: 300,
      // opacity: [0 , 1],
    }, 0).add({
      targets: item.querySelector('.services-page__head'),
      duration: 800,
      translateX:  [-100, 0],
      opacity:  [0, 1],
    }, 200) .add({
      targets: item.querySelector('.services-page__list1'),
      duration: 800,
      translateX: [50, 0],
      opacity:  [0, 1],
    }, 800).add({
      targets: item.querySelector('.services-page__list2'),
      duration: 800,
      translateX: [100, 0],
      opacity:  [0, 1],
    }, 1100).add({
      targets: item.querySelector('.services-page__list3'),
      duration: 800,
      translateX:  [150, 0],
      opacity:  [0 , 1],
    }, 1400)
    .add({  // Анимация органов навигации
      targets: document.querySelector('a.logo--image'), 
      easing: 'easeInQuad',
      duration: 300,
      opacity: [1, 1],
      translateX: [-160, 0],
    }, 400).add({
      targets: document.querySelector('a.logo--text'),
      easing: 'easeInQuad',
      duration: 300,
      opacity: [1, 1],
      translateX: [-160, 0],
    }, 400)
    // .add({
    //   targets: document.querySelector('.wrap-burger'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [0, 1],
    //   translateX: [100, 0],
    // }, 400)
    ;
  
    return serviceInAnimations.finished;
  }
 
};
