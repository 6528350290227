import anime from 'animejs';
import { cancelConversionsFunction } from '../app';

export const completedWorkIn = (item) => {
  // if (document.getElementById('.burger-menu--active')) {
  //   console.log('.burger-menu--active', document.getElementById('.burger-menu--active'));
    
  // }
  
  var qwer = document.querySelector('body');
  
  const completedWorkInAnimations = anime.timeline({
    easing: 'linear',
   })
  .add({
    begin: cancelConversionsFunction(qwer),
    targets: item,
    duration: 1000,
    opacity: [0 , 1],
  }, 0)
  .add({
    targets: item.querySelector('.completed-work__section-start'),
    duration: 800,
    translateX:  [-1000, 0],
    opacity:  [0, 1],
  }, 200).add({
    targets: item.querySelector('.completed-work__section-continuation'),
    duration: 800,
    translateX:  [1000, 0],
    opacity:  [0, 1],
  }, 200)
  .add({
    targets: item.querySelector('.completed-work__object-info-name'),
    duration: 800,
    translateX:  [-100, 0],
    opacity:  [0 , 1],
  }, 1000)
  .add({
    targets: item.querySelector('.completed-work__section-continuation img'),
    duration: 800,
    scale:  [0.7, 1],
    opacity:  [0 , 1],
  }, 1000).add({
    targets: item.querySelector('.completed-work__object-info-year'),
    duration: 800,
    opacity:  [0 , 1],
  }, 1500).add({
    targets: item.querySelector('.completed-work__object-equipment'),
    duration: 800,
    translateX:  [-50, 0],
    opacity:  [0 , 1],
  }, 1900).add({
    targets: item.querySelector('.completed-work__object-time'),
    duration: 800,
    translateX:  [-50, 0],
    opacity:  [0 , 1],
  }, 2100)
  // Анимация органов навигации
  // .add({
  //   targets: document.querySelector('a.logo--image'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [0, 1],
  //   translateX: [-100, 0],
  // }, 400).add({
  //   targets: document.querySelector('a.logo--text'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [0, 1],
  //   translateX: [-100, 0],
  // }, 400).add({
  //   targets: document.querySelector('.wrap-burger'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [0, 1],
  //   translateX: [100, 0],
  // }, 400)
  .add({
    targets: document.querySelector('.nav__next'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [0, 1],
    translateX: [100, 0],
  }, 400).add({
    targets: document.querySelector('.nav__prev'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [0, 1],
    translateX: [-100, 0],
  }, 400);
  
  return completedWorkInAnimations.finished;
};

export const completedWorkOut = (item) => {
  
  var qwer = document.querySelector('body');
  
  const completedWorkOutAnimations = anime.timeline({
    easing: 'linear',
  })
  
  .add({
    begin: cancelConversionsFunction(qwer),
    targets: item,
    duration: 1000,
    opacity: [1, 0],
  }, 1800)
  .add({
    targets: item.querySelector('.completed-work__section-start'),
    duration: 800,
    opacity:  [1, 0],
  }, 1600).add({
    targets: item.querySelector('.completed-work__section-continuation'),
    duration: 800,
    opacity:  [1, 0],
  }, 1600).add({
    targets: item.querySelector('.completed-work__section-output'),
    duration: 500,
    opacity:  [1, 0],
  }, 1600).add({
    targets: item.querySelector('.completed-work__section-section-output-finish'),
    duration: 500,
    translateX:  [0, 500],
    opacity:  [1, 0],
  }, 1600)
  .add({
    targets: item.querySelector('.completed-work__section-output ul'),
    duration: 500,
    translateX:  [0, -50],
    opacity:  [1, 0],
  }, 600)
  .add({
    targets: item.querySelector('.completed-work__section-section-output-text'),
    duration: 500,
    translateX:  [0, 100],
    opacity:  [1, 0],
  }, 600)
  .add({
    targets: item.querySelector('.completed-work__object-info-name'),
    duration: 500,
    translateX:  [0, -100],
    opacity:  [1, 0],
  }, 600)
  .add({
    targets: item.querySelector('.completed-work__section-continuation img'),
    duration: 1000,
    scale:  [1, 0.7],
    opacity:  [1, 0],
  }, 600).add({
    targets: item.querySelector('.completed-work__object-info-year'),
    duration: 500,
    opacity:  [1, 0],
  }, 400).add({
    targets: item.querySelector('.completed-work__object-equipment'),
    duration: 500,
    translateX:  [0, -50],
    opacity:  [1, 0],
  }, 200).add({
    targets: item.querySelector('.completed-work__object-time'),
    duration: 500,
    translateX:  [0, -50],
    opacity:  [1, 0],
  }, 0)
  // Анимация органов навигации
  // .add({
  //   targets: document.querySelector('a.logo--image'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [1, 0],
  //   translateX: [0, -100],
  // }, 400).add({
  //   targets: document.querySelector('a.logo--text'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [1, 0],
  //   translateX: [0, -100],
  // }, 400).add({
  //   targets: document.querySelector('.wrap-burger'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [1, 0],
  //   translateX: [0, 100],
  // }, 400)
  .add({
    targets: document.querySelector('.nav__next'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [1, 0],
    translateX: [0, 100],
  }, 400).add({
    targets: document.querySelector('.nav__prev'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [1, 0],
    translateX: [0, -100],
  }, 400);
  
  return completedWorkOutAnimations.finished;
};
