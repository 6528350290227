import Highway from '@dogstudio/highway';
import { serviceIn } from '../animations/service-animation';
import { serviceOut } from '../animations/service-animation';
import { horizontallScroll, openServiceList, } from '../app';
import { enableConversionsFunction } from '../app';

class serviceTransition extends Highway.Transition {
  in({ from, done }) {
    serviceIn(from).then(function() {
      window.scrollTo(0,0);
      horizontallScroll();
      openServiceList();
      var qwer = document.querySelectorAll('.beginAdd');
      enableConversionsFunction(qwer);
      console.log("Пришли на страницу УСЛУГ");
      done();
    });
  }

  out({ from, done }) {
    serviceOut(from).then(function() {
      window.scrollTo(0,0);
      from.remove();
      openServiceList();
      var qwer = document.querySelectorAll('.beginAdd');
      enableConversionsFunction(qwer);
      console.log("Ушли с УСЛУГ");
      done();
    });
  }
}

export default serviceTransition;
