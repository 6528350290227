import anime from 'animejs';
import { cancelConversionsFunction } from '../app';

export const projectsToProjectsAnimation = (item) => {
  const element = item.childNodes[1];
  var qwer = document.querySelector('body');
  const projectsInAnimations = anime.timeline({
    easing: 'linear',
  }).add({
    begin: cancelConversionsFunction(qwer),
    targets: element,
    duration: 500,
    easing: 'linear',
    opacity: [0 , 1],
  }, 0).add({
    targets: document.querySelector('.projects-menu').childNodes[1],
    duration: 1400,
    easing: 'easeOutQuad',
    translateY: [-70, 10, 0],
    opacity:  [0, 1],
  }, 700).add({
    targets: document.querySelector('.projects-menu').childNodes[3],
    duration: 1400,
    easing: 'easeOutQuad',
    translateY: [-70, 10, 0],
    opacity:  [0, 1],
  }, 1000).add({
    targets: document.querySelector('.projects-menu').childNodes[5],
    duration: 1400,
    easing: 'easeOutQuad',
    translateY: [-70, 10, 0],
    opacity:  [0, 1],
  }, 1300)
  // .add({
  //   targets: document.querySelector('a.logo--image'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [0, 1],
  //   translateX: [-100, 0],
  // }, 400).add({
  //   targets: document.querySelector('a.logo--text'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [0, 1],
  //   translateX: [-100, 0],
  // }, 400).add({
  //   targets: document.querySelector('.wrap-burger'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [0, 1],
  //   translateX: [100, 0],
  // }, 400)
  .add({
    targets: document.querySelector('.nav__next'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [0, 1],
    translateX: [100, 0],
  }, 400).add({
    targets: document.querySelector('.nav__prev'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [0, 1],
    translateX: [-100, 0],
  }, 400);
  
  return projectsInAnimations.finished;
};

export const projectsAnimationToProject = (item) => {
  console.log('item', item);
  const element = item.childNodes[1];
  const block = item.closest('li.projects-menu__item');
  var qwer = document.querySelector('body');
  
  var elementNodes = document.querySelector('.projects-menu').childNodes;
  // console.log(elementNodes);
  var projectsOutAnimations = anime.timeline({
    easing: 'easeInQuad'
  })
  .add({
    begin: cancelConversionsFunction(qwer),
    // Expand the clicked block
    targets: element,
    duration: 1200,
    // width: window.innerWidth,
    //translateX: -offset,
    direction: 'normal',
    opacity: 0,
    // complete: 
  }, 600)
  // .add({
  //   targets: element,
  //   duration: 1600,
  //   easing: 'easeInQuad',
  //   opacity: [1 , 0],
  //   // translateY: 500
  // }, 600)

  .add({
    targets: elementNodes[1],
    duration: 1000,
    easing: 'easeInQuad',
    // scale: [1, 0],
    opacity:  [1 , 0],
  }, 600)
  .add({
    targets: elementNodes[3],
    duration: 1000,
    easing: 'easeInQuad',
    opacity:  [1 , 0],
  }, 400)
  .add({
    targets: elementNodes[5],
    duration: 1000,
    easing: 'easeInQuad',
    opacity:  [1 , 0],
  }, 600)
  .add({
    targets: elementNodes[9],
    duration: 1000,
    easing: 'easeInQuad',
    opacity:  [1 , 0],
  }, 600)

  // .add({
  //   targets: document.querySelector('a.logo--image'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [1, 0],
  //   translateX: [0, -100],
  // }, 400).add({
  //   targets: document.querySelector('a.logo--text'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [1, 0],
  //   translateX: [0, -100],
  // }, 400).add({
  //   targets: document.querySelector('.wrap-burger'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [1, 0],
  //   translateX: [0, 100],
  // }, 400)
  .add({
    targets: document.querySelector('.nav__next'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [1, 0],
    translateX: [0, 100],
  }, 400).add({
    targets: document.querySelector('.nav__prev'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [1, 0],
    translateX: [0, -100],
  }, 400);
  // if( elementNodes.length > 1 ) {
  //   elementNodes.forEach(function(element,index) {
  //     if( index > 1 && (index % 2 !== 0)) {
  //       projectsOutAnimations = projectsOutAnimations.add({
  //         targets: element,
  //         duration: 1600,
  //         easing: 'easeInQuad',
  //         // width: window.innerWidth,
  //         // translateX: window.innerWidth,
         
  //         opacity:  [1 , 0],
  //       }, 600);
  //     }
  //   });
  // }
  return projectsOutAnimations.finished;
};
