import anime from 'animejs';

export const error404In = (item) => {
  const error404Animation = anime.timeline({
    easing: 'easeInQuad'
  }).add({
    targets: document.querySelector('main'),
    opacity: [0, 1],
    duration: 800
  }, 300);

  return error404Animation.finished;
};

export const error404Out = (item) => {
  const error404Animation = anime.timeline({
    easing: 'easeInQuad'
  }).add({
    targets: document.querySelector('main'),
    opacity: [1, 0],
    duration: 800
  }, 300);

  return error404Animation.finished;
};
