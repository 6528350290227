import Highway from '@dogstudio/highway';
import { completedWorkIn } from '../animations/completed-work-animation';
import { completedWorkOut } from '../animations/completed-work-animation';
import { horizontallScroll, openServiceList } from '../app';
import { enableConversionsFunction } from '../app';

class completedWorkTransition extends Highway.Transition {
  in({ from, done }) {
    completedWorkIn(from).then(function() {
      window.scrollTo(0,0);
      horizontallScroll();
      openServiceList();
      var qwer = document.querySelectorAll('.beginAdd');
      enableConversionsFunction(qwer);
      console.log("Пришли на прокТ");
      done();
    });
  }

  out({ from, done }) {
    completedWorkOut(from).then(function() {
      window.scrollTo(0,0);
      var qwer = document.querySelectorAll('.beginAdd');
      enableConversionsFunction(qwer);
      from.remove(); 
      console.log("Ушли с проектА");
      done(); 
    });
  }
}

export default completedWorkTransition;
