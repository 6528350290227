import Highway from '@dogstudio/highway';
import { servicesIn } from '../animations/services-animation';
import { servicesOut } from '../animations/services-animation';
import { horizontallScroll, verticalScrollTop, openServiceList } from '../app';
import { enableConversionsFunction } from '../app';

class servicesTransition extends Highway.Transition {
  in({ from, done }) {
    servicesIn(from).then(function() {
      window.scrollTo(0,0);
      verticalScrollTop();
      horizontallScroll();
      openServiceList();
      
      var qwer = document.querySelectorAll('.beginAdd');
      enableConversionsFunction(qwer);
      console.log("Пришли на прокТ");
      done();
    });
  }

  out({ from, done }) {
    servicesOut(from).then(function() {
      window.scrollTo(0,0);
      
      from.remove();
      
      openServiceList();
      var qwer = document.querySelectorAll('.beginAdd');
      enableConversionsFunction(qwer);
      console.log("Ушли с проектА");
      done();
    });
  }
}

export default servicesTransition;
