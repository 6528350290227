import Highway from '@dogstudio/highway';
import { servicesContextIn } from '../animations/servicesContext-animation';
import { servicesContextOut } from '../animations/servicesContext-animation';
import { horizontallScroll, openServiceList } from '../app';
import { enableConversionsFunction } from '../app';

class servicesContextTransition extends Highway.Transition {
  in({ from, done }) {
    servicesContextIn(from).then(function() {
      window.scrollTo(0,0);
      openServiceList();
      horizontallScroll();
      var qwer = document.querySelectorAll('.beginAdd');
      enableConversionsFunction(qwer);
      console.log("Пришли на прокТ");
      done();
    });
  }

  out({ from, done }) {
    servicesContextOut(from).then(function() {
      window.scrollTo(0,0);
      from.remove();
      var qwer = document.querySelectorAll('.beginAdd');
      enableConversionsFunction(qwer);
      console.log("Ушли с проектА");
      done();
    });
  }
}

export default servicesContextTransition;
