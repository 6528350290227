import Highway from '@dogstudio/highway';
import anime from 'animejs';
import { TweenMax } from 'gsap/TweenMax';
import IndexTransition from './transitions/index-transition';
import ProjectTransition from './transitions/project-transition';
import RequestTransition from './transitions/request-transition';
import aStaffTransition from './transitions/aStaff-transition';
import projectsTransition from './transitions/projects-transition';
import contactsTransition from './transitions/contacts-transition';
import completedWorkTransition from './transitions/completed-work-transition';
import servicesTransition from './transitions/services-transition';
import servicesContextTransition from './transitions/servicesContext-transition';
import serviceTransition from './transitions/service-transition';
import error404Transition from './transitions/error404-transition';
import { indexToProjectAnimationIn } from './animations/project-to-index';


// Удаление кнопок навигации на страницах услуг, запроса и контактов

export function deleteNavButton() {
  let servicesPage = document.querySelector('.services__container');
  let requestPage = document.querySelector('.request');
  let contactsPage = document.querySelector('.contacts');
  let error404Page = document.querySelector('.error-404__container');
  let navButtonLeft = $('.nav__prev');
  let navButtonRight = $('.nav__next');

  if(servicesPage || requestPage || contactsPage || error404Page) {
    navButtonLeft.removeClass('nav__prev--active');
    navButtonRight.removeClass('nav__next--active');
  }else if (!(servicesPage || requestPage || contactsPage || error404Page) && $(document).width() > 1024) {
    navButtonLeft.removeClass('nav__prev--active');
    navButtonRight.addClass('nav__next--active');
  }
}
deleteNavButton();

// Вертикальный скролл при помощи кнопки 'наверх'

export function verticalScrollTop() {
  if( $(document).width() <= 1024 ) {
    $(window).scroll(function() {
      if($(document).scrollTop() > document.documentElement.clientHeight/2) {
        $('.nav__top').fadeIn(300);
      }else {
        $('.nav__top').fadeOut(300);
      }
    });
    $('.nav__top').on('click', function() {
      $('body,html').animate({scrollTop:0},500);
    });
  }
}
verticalScrollTop();




if(document.querySelector('[data-router-view="services"]') && $(document).width() < 1024) {
  document.querySelector('a.logo--image').style.transform = 'translateX(-160px)';
  document.querySelector('a.logo--text').style.transform = 'translateX(-160px)';
}

export function showLogoOutServices() {
  let logoFlag = true;
  $('.burger').on('click', function() {
    if($(document).width() < 1024) {
      if(document.querySelector('[data-router-view="services"]') && logoFlag) {
        const animeLogoOutServices = anime.timeline({
          easing: 'easeInQuad'
        }).add({
          targets: document.querySelector('a.logo--image'),
          easing: 'easeInQuad',
          duration: 300,
          opacity: [1, 1],
          translateX: [-160, 0],
        }, 700).add({
          targets: document.querySelector('a.logo--text'),
          easing: 'easeInQuad',
          duration: 300,
          opacity: [1, 1],
          translateX: [-160, 0],
        }, 700);
        logoFlag = false;
      } else if(document.querySelector('[data-router-view="services"]') && !logoFlag) {
        const animeLogoOutServices = anime.timeline({
          easing: 'easeInQuad'
        }).add({
          targets: document.querySelector('a.logo--image'),
          easing: 'easeInQuad',
          duration: 300,
          opacity: [1, 1],
          translateX: [0, -160],
        }, 200).add({
          targets: document.querySelector('a.logo--text'),
          easing: 'easeInQuad',
          duration: 300,
          opacity: [1, 1],
          translateX: [0, -160]
        }, 200);
        logoFlag = true;
      }
    }
    
  });
  $('.burger-menu__link-transition').on('click', function() {
    if($(document).width() < 1024) {
      if(document.querySelector('[data-router-view="services"]')) {
        const animeLogoOutServices = anime.timeline({
          easing: 'easeInQuad'
        }).add({
          targets: document.querySelector('a.logo--image'),
          easing: 'easeInQuad',
          duration: 300,
          opacity: [1, 1],
          translateX: [-160, 0],
        }, 700).add({
          targets: document.querySelector('a.logo--text'),
          easing: 'easeInQuad',
          duration: 300,
          opacity: [1, 1],
          translateX: [-160, 0],
        }, 700);
      }
    }
  });
}
showLogoOutServices();



// Горизонтальный скролл при помощи мыши и стрелок

var prevVal = 0;
export function horizontallScroll() {
  // console.log('width',$(document).width());

  if( $(document).width() <= 1024 ) {
    window.scrollTo(0, 0);
    return false;
  } else {
    if(!(servicesPage || requestPage || contactsPage || error404Page)) {
      var offsetPage;
    }else {
      offsetPage = $('.h-scroll').offset().top;
    }

    var pixels = 0;
    var limitScroll =  0;
    var widthPage = 0;
    var scrollHeight = $('body').height();
    let servicesPage = document.querySelector('.services__container');
    let requestPage = document.querySelector('.request');
    let contactsPage = document.querySelector('.contacts');
    let error404Page = document.querySelector('.error-404__container');

    $('.h-scroll > *').each(function() {
      widthPage += $(this).outerWidth();
    });
    if(widthPage == 0 && prevVal !=0 ) {
      widthPage = prevVal;
    }
    if(!(servicesPage || requestPage || contactsPage || error404Page)) {
      TweenMax.set('main', {height: widthPage, overflowY: 'scroll'});
    }else {
      TweenMax.set('main', {height: scrollHeight, overflowY: 'scroll'});
    }

    limitScroll = widthPage - $('body').width();

    //Чтобы при открытии страницы стрелочка 'влево' была скрыта
    if(offsetPage == 0 && pixels == 0) {
      $('.nav__prev').removeClass('nav__prev--active');
    }
    // Код для клика на стрелку 'вправо'
    $('.nav__next').on('click', function() {
      pixels = 1*(pixels + 400);
      $(document).scrollTop(pixels);
      // При нажатии на стрелку 'вправо' появляется стрелка 'влево'
      $('.nav__prev').addClass('nav__prev--active');
      // При подходе к правому краю страницы скрывать стрелку
      if(pixels > $('.h-scroll').outerWidth() - $('body').width() - 100) {
        $('.nav__next').addClass('nav__next--active');
        pixels = limitScroll;
      }
      // Анимация
      TweenMax.to('.h-scroll', 1,{x: -pixels});
    });
    // Код для клика на стрелку 'влево'
    $('.nav__prev').on('click', function() {
      pixels = 1*(pixels - 400);
      $(document).scrollTop(pixels);
      // Анимация
      if((pixels > 0)  && (offsetPage !== 0)) {
        TweenMax.to('.h-scroll', 1,{x: -pixels});
      }
      // При подходе к левому краю страницы скрывать стрелку
      if(pixels <= 100) {
        $('.nav__prev').removeClass('nav__prev--active');
      }
    });

    // Код для скролла мышью
    $(window).on('scroll', function() {
      
      pixels = $(document).scrollTop();
      // Появление и скрытие стрелки 'влево' при подходе и отходе от левого края страницы
      if( pixels >= 99) {
        $('.nav__prev').addClass('nav__prev--active');
      }else {
        $('.nav__prev').removeClass('nav__prev--active');
      }
      // Появление и скрытие стрелки 'вправо' при подходе и отходе от правого края страницы

      if(pixels > limitScroll - 100) {
        pixels = limitScroll;
        $('.nav__next').removeClass('nav__next--active');
      }else {
        if(!(servicesPage || requestPage || contactsPage)) {
          $('.nav__next').addClass('nav__next--active');
        }
      }
      let scrollValue = -1 * pixels;


      // Анимация
      if( limitScroll >= pixels && offsetPage !== limitScroll && !(servicesPage || requestPage || contactsPage)) {
        TweenMax.to('.h-scroll', 1 ,{ x: scrollValue});
      }

    });
  }
  return $('.h-scroll').outerWidth();
}
$(window).on('resize', function() {
  if($(document).width() > 1024) {
    horizontallScroll();
  }
});
prevVal = horizontallScroll();
$('.preloader .preloader__text').on('classChange', function() {
  horizontallScroll();
});


const H = new Highway.Core({
  transitions: {
    index: IndexTransition,
    about: ProjectTransition,
    request: RequestTransition,
    aStaff: aStaffTransition,
    projects: projectsTransition,
    contacts: contactsTransition,
    completedWork: completedWorkTransition,
    services: servicesTransition,
    contextual: {
      foo: servicesContextTransition
    },
    service: serviceTransition,
    error404: error404Transition
  }
});

function preventDefault(e) {
  e = e || window.event;
  if (e.preventDefault)
    e.preventDefault();
  e.returnValue = false;
}
function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}



function disableScroll() {
  if (window.addEventListener) // older FF
    window.addEventListener('DOMMouseScroll', preventDefault, false);
  document.addEventListener('wheel', preventDefault, {passive: false}); // Disable scrolling in Chrome
  window.onwheel = preventDefault; // modern standard
  window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
  window.ontouchmove  = preventDefault; // mobile
  document.onkeydown  = preventDefaultForScrollKeys;
}

function enableScroll() {
  if (window.removeEventListener)
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
  document.removeEventListener('wheel', preventDefault, {passive: false}); // Enable scrolling in Chrome
  window.onmousewheel = document.onmousewheel = null;
  window.onwheel = null;
  window.ontouchmove = null;
  document.onkeydown = null;
}

disableScroll();

var preloadFlag = true;
function blockAnimate() {

  var length = $('.preloader .preloader__text').length - 1;
  $('.preloader .preloader__text').each(function(index) {
    if($(this).hasClass('active') && index != length) {
      $(this).removeClass('active').fadeOut(100).next('.preloader__text').addClass('active').delay(100).fadeIn(100).trigger('classChange');
      return false;
    } else if (index == length) {
      if(preloadFlag) {

        preloadFlag = false;
        const animatePreloader = anime.timeline({
          easing: 'cubicBezier(0.965, 0.010, 0.225, 0.845)',
        }).add({
          targets: '.strip',
          width:  1000,
          duration: 250
        }).add({
          targets: '.substrip',
          width:  1000,
          duration: 10
        }, 250).add({
          targets: '.substrip',
          width:  0,
          duration: 250
        }).add({
          targets: '.strip',
          width: [1000, 0],
          duration: 10,
          complete: function() {
            $('.text').fadeOut(100);
            $('.preloader__logo').delay(100).fadeIn(1000);
            $('.preloader__logo').fadeOut(500);
            $('.preloader').delay(500).fadeOut(1000);
            $('.main-page-wrap').delay(1000).fadeIn(1500);
            $('.main-page-wrap').trigger('classChange');
            if(document.querySelector('[data-router-view="index"]')) {
              indexToProjectAnimationIn(document.querySelector('main'));
              var qwer = document.querySelectorAll('.beginAdd');
              enableConversionsFunction(qwer);
            }
            openServiceList();
            enableScroll();

            // повторить с интервалом 2 секунды
            let timerId = setInterval(() => horizontallScroll(), 100);

            // остановить вывод через 5 секунд
            setTimeout(() => { clearInterval(timerId); console.log('stop'); }, 1000);

          }
        }, 250);
      }
    }
  });
};
function preloader() {
  $('.main-page-wrap').fadeOut(0);
  const animatePreloader = anime.timeline({
    easing: 'cubicBezier(0.965, 0.010, 0.225, 0.845)',
  }).add({
    targets: '.strip',
    width:  1000,
    duration: 250
  }).add({
    targets: '.substrip',
    width:  1000,
    duration: 10
  }, 250).add({
    targets: '.substrip',
    width:  0,
    duration: 250
  }).add({
    targets: '.strip',
    width: [1000, 0],
    duration: 10,
    complete: function() {
      $(document).ready(function() {
        $('.preloader .preloader__text').eq(0).addClass('active').fadeIn(100);
        let timerId = setInterval(blockAnimate, 500);
        setTimeout(() => {clearInterval(timerId);}, 4000);
      });
    }
  }, 250);
}
preloader();



// Открытие меню

// $('.burger').on('click', function() {
//   var burger = document.querySelector('.burger');
//   var burgerMenu = document.querySelector('.burger-menu');
//   var services = document.querySelector('.services');
//   var burgerMenuList = document.querySelector('.burger-menu__list');
  
//   if(!document.querySelector('.services--active')) {
//     console.log('saqwe');
//     burger.classList.toggle('burger--active');
//     burgerMenu.classList.toggle('burger-menu--active');
//   }
//   else {
//     burger.classList.toggle('burger--active');
//     $('.burger').on('click', function() {
      
//       services.classList.toggle('services--active');
//       burgerMenuList.classList.toggle('burger-menu__list--active');
//     });
//   }
// });


// Переход в подменю 'Наши услуги'

// $('.burger-menu__link-services').on('click', function() {
//   var services = document.querySelector('.services');
//   var burgerMenuList = document.querySelector('.burger-menu__list');
//   var burger = document.querySelector('.burger');
  
//   services.classList.toggle('services--active');
//   burgerMenuList.classList.toggle('burger-menu__list--active');
//   if(document.querySelector('.services--active')) {
//     burger.classList.toggle('burger--active');
//     $('.burger').on('click', function() {
//       services.classList.toggle('services--active');
//       burgerMenuList.classList.toggle('burger-menu__list--active');
//     });
//   }
// });


// Выбор нужно пункта меню и скрытие остальных
// export function servicesMenuAction() {
//   console.log('servicesMenuAction');
//   $('.services-page__subitem').on('click', function() {
//     if($(document).width() > 1024) {
//       // $('main').addClass('test');
//       // $('main').addClass('test2');
//       console.log('servicesMenuAction CLICK');
//       // $(this).parent().parent().siblings().fadeOut(500); // Убираем соседние менюшки
//       // console.log('Смерть1');
      
//       // $(this).parents('.services__list').siblings().fadeOut(500); // Убираем соседние списки
//       // console.log('Смерть2');
//       $('.after').removeClass('after');
//       $(this).parent().parent().find('*').addClass('after');
//       $('.before').removeClass('before');
//       let thisElem = this;
//       setTimeout(function() {
//         $(thisElem).parents('.services__list').toggleClass('services-page__list--active'); // Убираем максимальную ширину списка
//         // $('.services__dots').removeClass('services__dots--hidden').addClass('services__dots--active');
//       }, 500);

//       $('.services-page').toggleClass('services-page--active');


//     }else {
//       $('.services-page--active').css('left', '100vw');
//       $('.logo').fadeOut(300);
//       setTimeout(function() {
//         $('.arrow-back').addClass('arrow-back--active');
//       }, 300);
//     }

//   });


//   $('.arrow-back').on('click', function() {
//     $('.services-page--active').css('left', '0');
//     setTimeout(function() {

//       $('.logo').fadeIn(300);
//       $('.arrow-back').removeClass('arrow-back--active');
//       setTimeout(function() {
//         window.scrollTo(0,0);
//       },700);
//     }, 300);

//   });
//   $('.services__dots').on('click', function() {
//     if($(document).width() > 1024) {
//       if( $(this).hasClass('services__dots--active') ) {
//         $('.after').removeClass('after').addClass('before');
//       } else {
//         $('.before').removeClass('before').addClass('after');
//       }
//       $('.services-page').toggleClass('services-page--active');
//       $('.services__dots').toggleClass('services__dots--active');

//       if($('main').hasClass('test') && $('main').hasClass('test2')) {
//         setTimeout(function() {
//           $('main').removeClass('test');
//           $('.services__menu.services-page__menu *').removeClass('hidden');
//           $('.services-page__subitem').parent().parent().siblings().fadeIn(300); // Добавляем соседние менюшки
//           $('.services-page__subitem').parents('.services__list').siblings().fadeIn(300); // Добавляем соседние списки
//           $('.services-page__subitem').parents('.services__list').addClass('services-page__list--active');
//         }, 1000);
//       } else {
//         if( $(this).hasClass('services__dots--active') ) {
//           $('.services__menu.services-page__menu *:not(.after)').addClass('hidden');
//           $('.services-page__list').addClass('services-page__list--active');
//         } else {
//           $('.services__menu.services-page__menu *:not(.after)').removeClass('hidden');
//         }
//         $('main').addClass('test');
//         $('.services-page__subitem').parent().parent().siblings().fadeIn(300); // Добавляем соседние менюшки
//         $('.services-page__subitem').parents('.services__list').siblings().fadeIn(300); // Добавляем соседние списки
//         $('.services-page__subitem').parents('.services__list').removeClass('services-page__list--active').delay(1000);
//       }
//     }
//   });
// }
//servicesMenuAction();

export function openServiceList() {
  if($(document).width() < 1024) {
    $('.services__subhead, .services-page__subhead').on('click', function() {
      if(!$(this).next($('.services__sublist, .services-page__sublist')).hasClass('services__sublist--active')) {
        $(this).next($('.services__sublist, .services-page__sublist')).toggleClass('services__sublist--active');
        $('.services__subhead, .services-page__subhead').removeClass('services__subhead--active');
      }else {
        $('.services__sublist, .services-page__sublist').removeClass('services__sublist--active');
        $(this).next($('.services__sublist, .services-page__sublist')).addClass('services__sublist--active');
        $('.services__subhead, .services-page__subhead').removeClass('services__subhead--active');
        $(this).toggleClass('services__subhead--active');
      }
    });
  }
}
openServiceList();


if ($('.burger-menu__link-transition').length > 0) {
  $('.burger-menu__link-transition').on('click', function() {
    $('.burger-menu').toggleClass('burger-menu--active');
    $('.burger').toggleClass('burger--active');
  });
};


// Функиция для работы страницы запроса.


export function requestFunction() {
  //  Выпадающее меню в форме на странице запроса

  if ($('.request-form__heard-wrap').length > 0) {
    $('.request-form__heard-wrap').on('click', function() {
      $('.request-form__heard-wrap').toggleClass('request-form__heard--active');
      $('.request-form__heard-list').toggleClass('request-form__heard-list--active');
    });

    $('.request-form__heard-item').on('click', function() {
      var selects = $(this).children('p').text();
      $('.request-form__heard').html(selects);
      $('.request-form__heard-input').val( $(this).data('id') );
    });
  }


  //  Выбор темы обращения на странице запроса

  if ($('.request-subject__item').length > 0) {

    $('.request-subject__item').on('click', function() {
      $('.request-subject__item').removeClass('request-subject__item--active');
      $(this).addClass('request-subject__item--active');
      var requestSubject = $(this).find('.request-subject__text').text();
      $('.request-form__request-subject').val(requestSubject);
    })
  }

  //  Проверка формы и отправка по клику на клавишу ок в popup'е


  if ($('.request-form__submit').length > 0) {

    $('.request-form').on('change', function() {
      var flag_form = true;
      var required_characters_email = new RegExp('([A-Z a-z А-Я а-я 0-9])+([@])+([A-Z a-z А-Я а-я 0-9])+([.])+([A-Z a-z А-Я а-я 0-9])');

      $('.request-form input[required], .request-form textarea[required]').each(function() {

        if ($(this).val() == '') {
          flag_form = false;
        }

        if ( $(this).hasClass('request-form__email') && true != required_characters_email.test($(this).val())) {
          flag_form = false;
        }
      });
      if (flag_form == true) {
        $('.request-form__submit').css('pointer-events','auto');
        $('.request-form__submit').addClass('add');
      } else {
        $('.request-form__submit').css('pointer-events','none');
      }
    });

    $('.request-form__submit').on('click', function() {
      event.preventDefault();
      $('.request-popup__wrap').addClass('request-popup__wrap--active').fadeToggle(500).css('display','flex');
    });

    $('.request-popup__button').on('click', function() {
      event.preventDefault();
      $('.request-form').submit();
      $('.request-popup__wrap').removeClass('request-popup__wrap--active').fadeToggle(500).css('display','flex');
    });
  }

  //  Запрет ввода всего кроме цифр, + и () в поле ввода телефона.

  $('.request-form__phone').keypress(function(event) {

    if (event.charCode && ( (event.charCode < 48 || event.charCode > 57) && event.charCode != 40 && event.charCode != 41 && event.charCode != 43))
      return false;
  });
};
requestFunction();




// функиция для анимации плиток ховером
function hoverAnimationFunction() {
  if ($('main').length > 0) {
    var mainAttr = $('main').data('router-view');

    if (mainAttr == 'projects') {
      $('.projects-menu__link').on('click', function() {
        event.preventDefault();
        $(this).parents('.projects-menu__item').addClass('hover');
        return false;
      });
    }

    if (mainAttr == 'index') {
      $('.item__link').on('click', function() {
        event.preventDefault();
        $(this).parent().addClass('hover');
        return false;
      });
    }
  }
};
hoverAnimationFunction();

export function cancelConversionsFunction(trigger) {
  // $(trigger).css({'pointer-events': 'none'});
  // $(trigger).addClass('beginAdd');
  if( trigger.length == undefined) {
    $(trigger).css({'pointer-events': 'none'});
    $(trigger).addClass('beginAdd');
    hoverAnimationFunction();
  } else {
    for( var i = 0; i < trigger.length; i++ ) {
      $(trigger[i]).css({'pointer-events': 'none'});
      $(trigger).addClass('beginAdd');
      console.log('triggerIn', trigger[i]);
      hoverAnimationFunction();
    }
  }
}
// cancelConversionsFunction();


// Наши услуги

let urlPage = document.location.pathname.split('/');

currentUrl(urlPage);

export function currentUrl(urlPageExplode) {
  if(urlPageExplode[1] == 'services' && urlPageExplode[2] != '') {
    $('.services-page__link').each(function(index, item) {
      let linkPageExplode = $(this).attr('href').split('/');
      if(linkPageExplode[2] == urlPageExplode[2]) {
        console.log(linkPageExplode, 'linkPageExplode');
        currentServises($(this));
      }
    });
  }
}

export function currentServises(item) {
  if($(document).width() > 1024) {
    item.addClass('current');
    $('.services-page__list').css('display','none');
    $('.services-page__item').css('display','none');
  
    console.log(item.parents('.services-page__list'));
    item.parents('.services-page__list').css({
      'display':'block',
      'max-width':'100%'
    });
    item.parents('.services-page__item').css('display','block');
  }
  
}
// Наши услуги

export function enableConversionsFunction(trigger) {
  $(trigger).removeClass('beginAdd');
  $(trigger).css({'pointer-events': 'auto'});
  /*
  if( trigger.length == undefined) {
    $(trigger).css({'pointer-events': 'auto'});
  } else {
    for( var i = 0; i < trigger.length; i++ ){
      $(trigger[i]).css({'pointer-events': 'auto'});
    }
  }*/
}

// window.onpopstate = function(event) {
//   console.log('location: ' + document.location + ', state: ' + JSON.stringify(event.state));
// };

if ($('.wrap-burger').length > 0) {
  let logoLink = $('.logo');
  var burgerButton = $('.burger');
  var burgerMenu = $('.burger-menu');
  var burgerMenuList = $('.burger-menu__list');
  
  var services = $('.services-menu');
  var burgerMenuLinkServices = $('.burger-menu__link-services');

  var burgerMenuLink = $('.burger-menu__link-transition, .services__link');

  // Открытие меню по клику на бургер
  burgerButton.on('click', function() {
    // Если есть открытый раздел услуг, то закрываем его
    if(services.hasClass('services-open')) {
      burgerButton.addClass('burger--active');
      services.fadeOut(500).removeClass('services-open');
      burgerMenuList.fadeIn(800).css('display','flex').addClass('menu-list-open');
    } else {
      // если открыто просто меню, то закрываем его
      if (burgerMenuList.hasClass('menu-list-open')) {
        burgerMenuList.fadeOut(600).removeClass('menu-list-open');
        burgerMenu.fadeOut(700);
        burgerMenu.removeClass('burger-menu--active');
        burgerButton.removeClass('burger--active');
      } else {
        // открываем само меню
        burgerButton.addClass('burger--active');
        burgerMenu.fadeIn(500).addClass('burger-menu--active');
        burgerMenuList.fadeIn(600).css('display','flex').addClass('menu-list-open');
      }
    }
  });
  // открытие меню услуг
  burgerMenuLinkServices.on('click', function() {
    
    burgerButton.removeClass('burger--active');
    burgerMenuList.fadeOut(500).removeClass('menu-list-open');
    services.fadeIn(800).css('display','flex').addClass('services-open');
    
    openServiceList();
  });
  // закрытие меню при клике на ссылки
  burgerMenuLink.on('click', function() {
    services.fadeOut(600).removeClass('services-open');
    burgerMenuList.fadeOut(700).removeClass('menu-list-open');
    burgerMenu.fadeOut(800);
    burgerMenu.removeClass('burger-menu--active');
    burgerButton.removeClass('burger--active');
  });
  // закрытие меню при клике на логотип
  logoLink.on('click', function() {
    if(burgerMenu.hasClass('burger-menu--active')) {
      burgerMenu.fadeOut(800);
      burgerButton.removeClass('burger--active');
      services.fadeOut(600).removeClass('services-open');
    }
  });
}
