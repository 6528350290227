import Highway from '@dogstudio/highway';
import { projectToRequestAnimation } from '../animations/project-request';
import { RequestAnimationToProject } from '../animations/project-request';
import { requestFunction, deleteNavButton, openServiceList } from '../app';
import { enableConversionsFunction } from '../app';

class requestTransition extends Highway.Transition {
  in({ from, done }) {
    projectToRequestAnimation(from).then(function() { 
      window.scrollTo(0,0);
      deleteNavButton();
      requestFunction(); 
      openServiceList();
      var qwer = document.querySelectorAll('.beginAdd');
      enableConversionsFunction(qwer);
      console.log("Пришли на запрос");
      done(); 
    });
  }

  out({ from, done }) {
    RequestAnimationToProject(from).then(function() { 
      window.scrollTo(0,0);
      deleteNavButton();
      var qwer = document.querySelectorAll('.beginAdd');
      enableConversionsFunction(qwer);
      from.remove(); 
      console.log("Ушли с запроса");
      done(); 
    });
  }
}

export default requestTransition;
