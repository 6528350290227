import anime from 'animejs';
import { cancelConversionsFunction } from '../app';

export const projectToAStaffAnimation = (item) => {
  const element = item.childNodes[1];
  const offset = element.offsetLeft;
  // console.log("item", item);
  // console.log("item.querySelector", item.querySelector('.acquaintance__link'));
  
  
  var qwer = document.querySelector('body');
  
  const aStaffInAnimations = anime.timeline({
    easing: 'easeInQuad',
  }).add({
    begin: cancelConversionsFunction(qwer),
    targets: element,
    duration: 500,
    easing: 'linear',
    opacity: [0 , 1],
  }, 200)
  .add({
    targets: document.querySelector('.menu__item--no-animation .item__text'),
    duration: 800,
    easing: 'easeOutQuad',
    translateX: [-50, 0],
    opacity: [0, 1],
  }, 200)
  .add({
    targets: document.querySelector('.menu__item--no-animation .item__link-back'),
    duration: 500,
    easing: 'easeOutQuad',
    translateX: [-50, 0],
    opacity: [0, 1],
  }, 600)
  .add({
    targets: document.querySelector('.menu__item--animation-one .item__text'),
    duration: 800,
    easing: 'linear',
    translateY: [-50, 10, 0],
    opacity:  [0 , 1],
  }, 800).add({
    targets: document.querySelector('.menu__item--animation-two .item__text'),
    duration: 800,
    easing: 'linear',
    translateY: [-50, 20, 0],
    opacity:  [0 , 1],
  }, 1000).add({
    targets: document.querySelector('.menu__item--animation-three .item__text'),
    duration: 800,
    easing: 'linear',
    translateY: [-50, 30, 0],
    opacity:  [0 , 1],
  }, 1200)
  // .add({
  //   targets: document.querySelector('a.logo--image'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [0, 1],
  //   translateX: [-100, 0],
  // }, 400).add({
  //   targets: document.querySelector('a.logo--text'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [0, 1],
  //   translateX: [-100, 0],
  // }, 400).add({
  //   targets: document.querySelector('.wrap-burger'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [0, 1],
  //   translateX: [100, 0],
  // }, 400)
  .add({
    targets: document.querySelector('.nav__next'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [0, 1],
    translateX: [100, 0],
  }, 400).add({
    targets: document.querySelector('.nav__prev'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [0, 1],
    translateX: [-100, 0],
  }, 400);

  return aStaffInAnimations.finished;
};

export const aStaffAnimationToProject = (item) => {
  
  const element = item.childNodes[1];
  const offset = element.offsetLeft;
  var qwer = document.querySelector('body');
  
  
  const aStaffOutAnimations = anime.timeline({
    easing: 'easeInQuad',
  }).add({
    begin: cancelConversionsFunction(qwer),
    targets: element,
    duration: 500,
    easing: 'linear',
    opacity: [1 , 0],
  }, 1700).add({
    targets: document.querySelector('.menu__item--animation-all'),
    duration: 500,
    easing: 'easeOutQuad',
    opacity:  [1 , 0],
  }, 200).add({
    targets: document.querySelector('.menu__item--animation-three'),
    duration: 500,
    easing: 'easeOutQuad',
    opacity:  [1 , 0],
  }, 400).add({
    targets: document.querySelector('.menu__item--animation-two'),
    duration: 500,
    easing: 'easeOutQuad',
    opacity:  [1 , 0],
  }, 600).add({
    targets: document.querySelector('.menu__item--animation-one'),
    duration: 500,
    easing: 'easeOutQuad',
    opacity:  [1 , 0],
  }, 800)
  .add({
    targets: document.querySelector('.menu__item--no-animation .item__link-back'),
    duration: 500,
    easing: 'easeOutQuad',
    translateX: [0, -50],
    opacity: [1, 0],
  }, 600)
  .add({
    targets: document.querySelector('.menu__item--no-animation .item__text'),
    duration: 800,
    easing: 'easeOutQuad',
    translateX: [0, -50],
    opacity: [1, 0],
  }, 900)
  .add({
    targets: document.querySelector('.menu__item--no-animation'),
    duration: 500,
    easing: 'easeOutQuad',
    opacity: [1, 0],
  }, 1000)
  // .add({
  //   targets: document.querySelector('a.logo--image'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [1, 0],
  //   translateX: [0, -100],
  // }, 400).add({
  //   targets: document.querySelector('a.logo--text'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [1, 0],
  //   translateX: [0, -100],
  // }, 400).add({
  //   targets: document.querySelector('.wrap-burger'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [1, 0],
  //   translateX: [0, 100],
  // }, 400)
  .add({
    targets: document.querySelector('.nav__next'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [1, 0],
    translateX: [0, 100],
  }, 400).add({
    targets: document.querySelector('.nav__prev'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [1, 0],
    translateX: [0, -100],
  }, 400);
  
  
  return aStaffOutAnimations.finished;
};