import anime from 'animejs';
import { cancelConversionsFunction } from '../app';

export const projectToContactsAnimation = (item) => {
  
  // console.log('item', item);
  // console.log('item.childNodes[3]', item.childNodes[3]);
  const element = item;
  var qwer = document.querySelector('body');
  const contactsInAnimations = anime.timeline({
    easing: 'easeInQuad',
  }).add({
    begin: cancelConversionsFunction(qwer),
    targets: element,
    duration: 200,
    easing: 'linear',
    opacity: [0 , 1],
  }, 0).add({
    targets: document.querySelector('.contacts__wrap'),
    duration: 900,
    easing: 'easeOutQuad',
    translateX: [-100, 0],
    opacity:  [0 , 1],
  }, 200).add({
    targets: document.querySelector('.contacts-title'),
    duration: 500,
    easing: 'easeOutQuad',
    translateX: [-75, 0],
    opacity:  [0 , 1],
  }, 700)
  .add({
    targets: document.querySelector('.contacts-phone'),
    duration: 500,
    easing: 'easeOutQuad',
    translateX:  [-50, 0],
    opacity:  [0 , 1],
  }, 1000)
  .add({
    targets: document.querySelector('.contacts-adress'),
    duration: 500,
    easing: 'easeOutQuad',
    translateX:  [-50, 0],
    opacity:  [0 , 1],
  }, 1300)
  .add({
    targets: document.querySelector('.contacts-subject__title'),
    duration: 500,
    easing: 'easeOutQuad',
    translateX:  [-50, 0],
    opacity:  [0 , 1],
  }, 1600)
  .add({
    targets: document.querySelector('.contacts-mail'),
    duration: 500,
    easing: 'easeOutQuad',
    translateX:  [-50, 0],
    opacity:  [0 , 1],
  }, 1900)
  .add({
    targets: document.querySelector('.maps'),
    duration: 900,
    easing: 'easeOutQuad',
    scale:  [0.95, 1],
    opacity:  [0 , 1],
  }, 200)
  // .add({
  //   targets: document.querySelector('a.logo--image'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [0, 1],
  //   translateX: [-100, 0],
  // }, 400).add({
  //   targets: document.querySelector('a.logo--text'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [0, 1],
  //   translateX: [-100, 0],
  // }, 400).add({
  //   targets: document.querySelector('.wrap-burger'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [0, 1],
  //   translateX: [100, 0],
  // }, 400)
  ;
  
  return contactsInAnimations.finished;
};

export const contactsAnimationToProject = (item) => {
  const element = item.childNodes[1];
  const offset = element.offsetLeft;
  var qwer = document.querySelector('body');
  
  const contactsOutAnimations = anime.timeline({
    
    easing: 'easeInQuad',
  }).add({
    begin: cancelConversionsFunction(qwer),
    targets: element,
    duration: 200,
    easing: 'linear',
    opacity: [1 , 0],
  }, 2200)
  .add({
    targets: document.querySelector('.contacts-mail'),
    duration: 500,
    easing: 'easeOutQuad',
    translateX:  [0,-50],
    opacity:  [1, 0],
  }, 0)
  .add({
    targets: document.querySelector('.contacts-subject__title'),
    duration: 500,
    easing: 'easeOutQuad',
    translateX:  [0, -50],
    opacity:  [1, 0],
  }, 200)
  .add({
    targets: document.querySelector('.contacts-adress'),
    duration: 500,
    easing: 'easeOutQuad',
    translateX:  [0, -50],
    opacity:  [1, 0],
  }, 500)
  .add({
    targets: document.querySelector('.contacts-phone'),
    duration: 500,
    easing: 'easeOutQuad',
    translateX:  [0, -50],
    opacity:  [1, 0],
  }, 800)
  .add({
    targets: document.querySelector('.contacts-title'),
    duration: 500,
    easing: 'easeOutQuad',
    translateX: [0, -75],
    opacity:  [1, 0],
  }, 1100)
  .add({
    targets: document.querySelector('.contacts__wrap'),
    duration: 500,
    easing: 'easeOutQuad',
    translateX: [0, -100],
    opacity:  [1, 0],
  }, 1400)
  .add({
    targets: document.querySelector('.maps'),
    duration: 500,
    easing: 'easeOutQuad',
    scale:  [1, 0.95],
    opacity:  [1, 0],
  }, 1400)
  // .add({
  //   targets: document.querySelector('a.logo--image'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [1, 0],
  //   translateX: [0, -100],
  // }, 400).add({
  //   targets: document.querySelector('a.logo--text'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [1, 0],
  //   translateX: [0, -100],
  // }, 400).add({
  //   targets: document.querySelector('.wrap-burger'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [1, 0],
  //   translateX: [0, 100],
  // }, 400)
  .add({
    targets: document.querySelector('.nav__next'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [1, 0],
    translateX: [100, 100],
  }, 400).add({
    targets: document.querySelector('.nav__prev'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [1, 0],
    translateX: [-100, -100],
  }, 400);
  
  return contactsOutAnimations.finished;
}
