import Highway from '@dogstudio/highway';
import { error404Out } from '../animations/error404-animation';


class error404Transition extends Highway.Transition {
  in({ to, done }) {
    error404In(to).then(function() {
      done(); 
    });
  }

  out({ from, trigger, done }) {
    error404Out(from).then(function() { 
      from.remove(); 
      done(); 
    });
  }
}

export default error404Transition;
