import Highway from '@dogstudio/highway';
import { projectsToProjectsAnimation } from '../animations/project-projects';
import { projectsAnimationToProject } from '../animations/project-projects';
import { horizontallScroll, verticalScrollTop, openServiceList } from '../app';
import { enableConversionsFunction } from '../app';

class projectsTransition extends Highway.Transition {
  in({ to, done }) {
    projectsToProjectsAnimation(to).then(function() { 
      window.scrollTo(0,0);
      horizontallScroll();
      verticalScrollTop();
      openServiceList();
      var qwer = document.querySelectorAll('.beginAdd');
      enableConversionsFunction(qwer);
      console.log("Пришли на проектЫ");
      done(); 
    });
  }

  out({ from, trigger, done }) {
    projectsAnimationToProject(from, trigger).then(function() { 
      window.scrollTo(0,0);
      horizontallScroll();
      verticalScrollTop();
      var qwer = document.querySelectorAll('.beginAdd');
      enableConversionsFunction(qwer);
      from.remove(); 
      console.log("Ушли с проектОВ");
      done(); 
    });
  }
}

export default projectsTransition;
