import anime from 'animejs';
import { cancelConversionsFunction } from '../app';
// import { read } from 'fs';


// const prevAll = element => {
//   let sublings = [];
//   let subling = element.previousElementSibling;

//   while (subling) {
//     if (subling && subling.tagName === element.tagName && subling.className === element.className) {
//       sublings.push(subling);
//     }
//     subling = subling.previousElementSibling;
//   }
//   return sublings;
// };

// const nextAll = element => {
//   let sublings = [];
//   let subling = element.nextElementSibling;

//   while (subling) {
//     if (subling && subling.tagName === element.tagName && subling.className === element.className) {
//       sublings.push(subling);
//     }
//     subling = subling.nextElementSibling;
//   }
//   return sublings;
// };

// function cancelConversionsFunction(trigger) {
//   if( trigger.length == undefined) {
//     $(trigger).css({"pointer-events": "none"});
//     console.log('triggerIn', trigger);
//   } else {
//     for( var i = 0; i < trigger.length; i++ ){
//       $(trigger[i]).css({"pointer-events": "none"});
//       console.log('triggerIn', trigger[i]);
//     }
//   }
  
// }

// function enableConversionsFunction(trigger) {
//   console.log("dsgfdsfgds");
  
//   if( trigger.length == undefined) {
//     $(trigger).css({"pointer-events": "auto"});
//   } else {
//     for( var i = 0; i < trigger.length; i++ ){
//       $(trigger[i]).css({"pointer-events": "auto"});
//     }
//   }
// }
export const indexToProjectAnimation = (container, item) => {
  // console.log('container_qwe', container);
  // console.log('container.childNodes[1]', container);
  const element = document.querySelector('.menu');
  // console.log(item);
  
  // console.log('element', element);
  // console.log('element.childNodes[3]', element.childNodes[3]);

  

  if(item.classList.contains('burger-menu__link') || item.classList.contains('services__link')) {
    var qwer = document.querySelector('body');
    
  const expandAnimations = anime.timeline({
    easing: 'linear',
  })
  .add({
    begin: cancelConversionsFunction(qwer),
    targets: document.querySelector('.item__link'),
    duration: 200,
    easing: 'linear',
    opacity: [1, 0],
    }, 1800)
  .add({
    targets: element.childNodes[1],
    duration: 500,
    easing: 'linear',
    opacity: [1, 0],
  }, 700)
  .add({
    targets: element.childNodes[3],
    duration: 500,
    easing: 'linear',
    opacity: [1, 0],
  }, 900).add({
    targets: element.childNodes[5],
    duration: 500,
    easing: 'linear',
    opacity: [1, 0],
  }, 1100)
  .add({
    targets: element.childNodes[7],
    duration: 500,
    easing: 'linear',
    opacity: [1, 0],
  }, 1300).add({
    targets: element.childNodes[9],
    duration: 500,
    easing: 'linear',
    opacity: [1, 0],
  }, 1500)




  .add({
    targets: element.childNodes[1].querySelector('div.item__text'),
    duration: 500,
    easing: 'easeOutQuad',
    translateY: [0, 70],
    opacity: [1, 0],
  }, 200).add({
    targets: element.childNodes[3].querySelector('div.item__text'),
    duration: 500,
    easing: 'easeOutQuad',
    translateY: [0, 70],
    opacity: [1, 0],
  }, 400).add({
    targets: element.childNodes[5].querySelector('div.item__text'),
    duration: 500,
    easing: 'easeOutQuad',
    translateY: [0, 70],
    opacity: [1, 0],
  }, 600).add({
    targets: element.childNodes[7].querySelector('div.item__text'),
    duration: 500,
    easing: 'easeOutQuad',
    translateY: [0, 70],
    opacity: [1, 0],
  }, 800).add({
    targets: element.childNodes[9].querySelector('div.item__text'),
    duration: 500,
    easing: 'easeOutQuad',
    translateY: [0, 70],
    opacity: [1, 0],
  }, 1000)



  // .add({
  //   targets: document.querySelector('a.logo--image'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [1, 0],
  //   translateX: [0, -100],
  // }, 400).add({
  //   targets: document.querySelector('a.logo--text'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [1, 0],
  //   translateX: [0, -100],
  // }, 400).add({
  //   targets: document.querySelector('.wrap-burger'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [1, 0],
  //   translateX: [0, 100],
  // }, 400)
  .add({
    targets: document.querySelector('.nav__next'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [1, 0],
    translateX: [0, 100],
  }, 400).add({
    targets: document.querySelector('.nav__prev'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [1, 0],
    translateX: [0, -100],
  }, 400);

  return expandAnimations.finished;

  }
  else{
    if(item.classList.contains('item__link')) {
      var qwer = document.querySelector('body');
      
      const element = item.closest('li.menu__item');
      const offset = element.offsetLeft;

      const expandAnimations = anime.timeline({
        easing: 'easeInQuad',
      }).add({
        begin: cancelConversionsFunction(qwer),
        // Move text
        targets: document.querySelector('div.item__text'),
        duration: 1400,
        easing: 'easeOutQuad',
        translateY: 100,
        opacity: 0,
      }, 100)
      // .add({
      //   // Move image
      //   targets: document.querySelector('img.item__img'),
      //   duration: 1300,
      //   easing: 'easeOutQuad',
      //   translateY: '10%',
      //   opacity: 0,
      //   scale: 0.8
      // }, 200)
      .add({
        targets: document.querySelector('button.nav__prev'),
        // translateX: -100,
        duration: 1200,
      }, 400).add({
        targets: document.querySelector('button.nav__next'),
        // translateX: 100,
        duration: 1200,
      }, 400).add({
        // Expand the clicked block
        targets: element,
        duration: 1600,
        width: window.innerWidth,
        //translateX: -offset,
        direction: 'normal',
        opacity: 0,
        // complete: 
      }, 600)
      // .add({
      //   targets: document.querySelector('a.logo--image'),
      //   easing: 'easeInQuad',
      //   duration: 300,
      //   opacity: [1, 0],
      //   translateX: [0, -100],
      // }, 400).add({
      //   targets: document.querySelector('a.logo--text'),
      //   easing: 'easeInQuad',
      //   duration: 300,
      //   opacity: [1, 0],
      //   translateX: [0, -100],
      // }, 400).add({
      //   targets: document.querySelector('.wrap-burger'),
      //   easing: 'easeInQuad',
      //   duration: 300,
      //   opacity: [1, 0],
      //   translateX: [0, 100],
      // }, 400)
      .add({
        targets: document.querySelector('.nav__next'),
        easing: 'easeInQuad',
        duration: 300,
        opacity: [1, 0],
        translateX: [0, 100],
      }, 400).add({
        targets: document.querySelector('.nav__prev'),
        easing: 'easeInQuad',
        duration: 300,
        opacity: [1, 0],
        translateX: [0, -100],
      }, 400);
    
      //Shrink anothers blocks
      // if (offset > 0) {
      //   expandAnimations.add({
      //     targets: [prevAll(element)],
      //     duration: 1600,
      //     width: 0,
      //     opacity: 0,
      //     translateX: -window.innerWidth,
      //   }, 600);
      // }
    
      // expandAnimations.add({
      //   targets: [nextAll(element)],
      //   duration: 1600,
      //   width: 0,
      //   opacity: 0,
      //   translateX: window.innerWidth - offset,
      // }, 600);
    
      return expandAnimations.finished;
    }
  }
};



export const indexToProjectAnimationNext = container => { 
  if($(document).width() > 1024) {
    const expandAnimationsNext = anime.timeline({
      targets: container,
      easing: 'easeOutQuad',
      // left: 0,
      offset: 0,
      duration: 100,
    }, 100).add({
      easing: 'easeInQuad',
      targets: container.querySelector('section.start'),
      duration: 600,
      width: ['100vw', '67vw'],
      opacity: [0, 1],
      direction: 'normal',
    }, 200)
    // .add({
    //   targets: document.querySelector('a.logo--image'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [0, 1],
    //   translateX: [-100, 0],
    // }, 400).add({
    //   targets: document.querySelector('a.logo--text'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [0, 1],
    //   translateX: [-100, 0],
    // }, 400).add({
    //   targets: document.querySelector('.wrap-burger'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [0, 1],
    //   translateX: [100, 0],
    // }, 400)
    .add({
      targets: document.querySelector('.nav__next'),
      easing: 'easeInQuad',
      duration: 300,
      opacity: [0, 1],
      translateX: [100, 0],
    }, 400).add({
      targets: document.querySelector('.nav__prev'),
      easing: 'easeInQuad',
      duration: 300,
      opacity: [0, 1],
      translateX: [-100, 0],
    }, 400);
    if(document.querySelector('div.advantages__container')) {
      expandAnimationsNext.add({
        targets: document.querySelector('div.advantages__container'),
        easing: 'easeInQuad',
        duration: 400,
        opacity: [0, 1],
        // translateY: [100, 0],
      }, 800);
    }
    return expandAnimationsNext.finished;
  } else {
    const expandAnimationsNext = anime.timeline({
      targets: container,
      easing: 'easeOutQuad',
      // left: 0,
      offset: 0,
      duration: 100,
    }, 100).add({
      easing: 'easeInQuad',
      targets: container.querySelector('section.start'),
      duration: 600,
      width: '100vw',
      opacity: [0, 1],
      direction: 'normal',
    }, 200)
    // .add({
    //   targets: document.querySelector('a.logo--image'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [0, 1],
    //   translateX: [-100, 0],
    // }, 400).add({
    //   targets: document.querySelector('a.logo--text'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [0, 1],
    //   translateX: [-100, 0],
    // }, 400).add({
    //   targets: document.querySelector('.wrap-burger'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [0, 1],
    //   translateX: [100, 0],
    // }, 400)
    .add({
      targets: document.querySelector('.nav__next'),
      easing: 'easeInQuad',
      duration: 300,
      opacity: [0, 1],
      translateX: [100, 0],
    }, 400).add({
      targets: document.querySelector('.nav__prev'),
      easing: 'easeInQuad',
      duration: 300,
      opacity: [0, 1],
      translateX: [-100, 0],
    }, 400);
    if(document.querySelector('div.advantages__container')) {
      expandAnimationsNext.add({
        targets: document.querySelector('div.advantages__container'),
        easing: 'easeInQuad',
        duration: 400,
        opacity: [0, 1],
        // translateY: [100, 0],
      }, 800);
    }
  
    return expandAnimationsNext.finished;
  }
  
};






//эксперименты
// .add({
//   // Expand the clicked block CLICKED ELEMENT
//   easing: 'linear',
//   targets: element,
//   duration: 1000,
//   // opacity: 0,
//   width: window.innerWidth,
//   translateX: -offset,
//   direction: 'normal',
// }, 600);

// // Shrink anothers blocks LEFT
// if (offset > 0) {
//   expandAnimations.add({
//     easing: 'linear',
//     targets: [prevAll(element)],
//     duration: 1000,
//     //width: 0,
//     translateX: -offset,
//   }, 0);
// }
// //RIGHT
// expandAnimations.add({
//   easing: 'linear',
//   targets: [nextAll(element)],
//   duration: 1000,
//   //width: 0,
//   translateX: -($('li.menu__item').eq(0).width()),
// }, 0);