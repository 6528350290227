import Highway from '@dogstudio/highway';
import { indexToProjectAnimationNext } from '../animations/index-to-project';
import { projectToIndexAnimation } from '../animations/project-to-index';
import { horizontallScroll, verticalScrollTop, openServiceList } from '../app';
import { enableConversionsFunction } from '../app';


class ProjectTransition extends Highway.Transition {
  in({ to, done }) {
    indexToProjectAnimationNext(to).then(function() { 
      window.scrollTo(0,0);
      horizontallScroll();
      verticalScrollTop();
      horizontallScroll();
      openServiceList();
      var qwer = document.querySelectorAll('.beginAdd');
      enableConversionsFunction(qwer);
      console.log("Пришли о компании");
      
      done(); 
    });
  }

  out({ from, done }) {
    projectToIndexAnimation(from).then(function() { 
      window.scrollTo(0,0);
      horizontallScroll();
      verticalScrollTop();
      horizontallScroll();
      var qwer = document.querySelectorAll('.beginAdd');
      enableConversionsFunction(qwer);
      from.remove(); 
      console.log("Ушли с о компании");
      done(); 
    });
  }
}

export default ProjectTransition;



