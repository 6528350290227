import Highway from '@dogstudio/highway';
import { indexToProjectAnimation } from '../animations/index-to-project';
import { indexToProjectAnimationIn } from '../animations/project-to-index';
import { horizontallScroll, verticalScrollTop,
         deleteNavButton, enableConversionsFunction, openServiceList } from '../app';

let lastClicked = null;

class IndexTransition extends Highway.Transition {
  in({ to, done }) {
    indexToProjectAnimationIn(to, lastClicked).then(function() { 
      window.scrollTo(0,0);
      deleteNavButton();
      horizontallScroll();
      verticalScrollTop();
      openServiceList();
      var qwer = document.querySelectorAll('.beginAdd');
      enableConversionsFunction(qwer);
      console.log("Пришли на главную");
      done(); 
    });
  }

  out({ from, trigger, done }) {
    // console.log(trigger);
    // if($('li.nav__item').length > 1){
    //   lastClicked = trigger.closest('li.nav__item');
    // } 
    indexToProjectAnimation(from, trigger)
    .then(function() { 
      window.scrollTo(0,0);
      deleteNavButton();
      horizontallScroll();
      verticalScrollTop();
      var qwer = document.querySelectorAll('.beginAdd');
      enableConversionsFunction(qwer);
      horizontallScroll();
      from.remove(); 
      console.log("Ушли с главной");
      done(); 
    });
  }
}

export default IndexTransition;
