import anime from 'animejs';
import { cancelConversionsFunction, showLogoOutServices } from '../app';
import { currentUrl } from '../app';
import { currentServises } from '../app';

export const servicesIn = (item) => {
  var qwer = document.querySelector('body');
  // console.log('item', item);
  console.log('servicesIn');
  let urlPage = document.location.pathname.split('/');
  // console.log(urlPage);
  currentUrl(urlPage);
  var servicesPageWrap = document.querySelector('.services-page-wrap');

  if($(document).width() > 1024) {
    const servicesInAnimations = anime.timeline({
      easing: 'easeInQuad',
     })
    .add({
      begin: cancelConversionsFunction(qwer),
      targets: item,
      duration: 300,
      // opacity: [0 , 1], 
    }, 0)
    .add({
      targets: item.querySelector('.services-page__head'),
      duration: 800,
      translateX:  [-100, 0],
      opacity:  [0, 1],
    }, 600)
    .add({
      targets: item.querySelector('.services-page__list1'),
      duration: 800,
      translateX: [-50, 0],
      opacity:  [0, 1],
    }, 600)
    .add({
      targets: item.querySelector('.services-page__list2'),
      duration: 800,
      translateX: [-100, 0],
      opacity:  [0, 1],
    }, 600)
    .add({
      targets: item.querySelector('.services-page__list3'),
      duration: 800,
      translateX:  [-150, 0],
      opacity:  [0 , 1],
    }, 600)
    .add({
      targets: servicesPageWrap,
      duration: 800,
      translateX:  [150, 0],
      opacity: [0, 1],
    }, 500)
    // .add({
    //   targets: document.querySelector('.services-inner-page'),
    //   duration: 800,
    //   width: ['100vw', '35vw']
    // }, 0)
    // Анимация органов навигации
    // .add({
    //   targets: document.querySelector('a.logo--image'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [0, 1],
    //   translateX: [0, -150],
    // }, 400).add({
    //   targets: document.querySelector('a.logo--text'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [0, 1],
    //   translateX: [0, -150],
    // }, 400)
    // .add({
    //   targets: document.querySelector('.wrap-burger'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [0, 1],
    //   translateX: [100, 0],
    // }, 400)
    .add({
      targets: document.querySelector('.services__dots'),
      easing: 'easeInQuad',
      duration: 300,
      opacity: [0, 1],
      translateX: [100, 0]
    }, 400);
    return servicesInAnimations.finished;
  }else {
    showLogoOutServices();
    const servicesInAnimations = anime.timeline({
      easing: 'linear',
    })
    .add({
      begin: cancelConversionsFunction(qwer),
      targets: item,
      duration: 800,
      opacity: [0 , 1], 
    }, 0)
    .add({
      targets: document.querySelector('a.logo--image'),
      easing: 'easeInQuad',
      duration: 300,
      opacity: [1, 1],
      translateX: [0, -160],
    }, 400).add({
      targets: document.querySelector('a.logo--text'),
      easing: 'easeInQuad',
      duration: 300,
      opacity: [1, 1],
      translateX: [0, -160],
    }, 400)
    // .add({
    //   targets: document.querySelector('.wrap-burger'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [0, 1],
    //   translateX: [100, 0],
    // }, 400)
    .add({
      targets: document.querySelector('.services__arrow'),
      easing: 'easeInQuad',
      duration: 300,
      opacity: [0, 1],
      translateX: [-100, 0]
    }, 700);
    
    return servicesInAnimations.finished;
  }
  
  
 
};

export const servicesOut = (item) => {
  if ($('.services-page-wrap').length > 0) {
    console.log('servicesOut');
    var servicesPageWrap = document.querySelector('.services-page-wrap');
  }
  if($(document).width() > 1024) {
    const servicesOutAnimations = anime.timeline({
      easing: 'easeInQuad',
    })
    .add({
      targets: item,
      duration: 300,
      // opacity: [1, 0],
    }, 1200)
    .add({
      targets: item.querySelector('.services-page__head'),
      duration: 800,
      translateX:  [0, -100],
      opacity: [1, 0],
    }, 600)
    .add({
      targets: item.querySelector('.services-page__list3'),
      duration: 800,
      translateX:  [0, -150],
      opacity: [1, 0],
    }, 600)
    .add({
      targets: item.querySelector('.services-page__list2'),
      duration: 800,
      translateX:  [0, -100],
      opacity: [1, 0],
    }, 600)
    .add({
      targets: item.querySelector('.services-page__list1'),
      duration: 800,
      translateX:  [0, -50],
      opacity: [1, 0],
    }, 600)
    .add({
      targets: servicesPageWrap,
      duration: 800,
      translateX:  [0, 150],
      opacity: [1, 0],
    }, 500)
  
    // Анимация органов навигации
    // .add({
    //   targets: document.querySelector('a.logo--image'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [1, 0],
    //   translateX: [0, -150],
    // }, 400)
    // .add({
    //   targets: document.querySelector('a.logo--text'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [1, 0],
    //   translateX: [0, -150],
    // }, 400)
    // .add({
    //   targets: document.querySelector('.wrap-burger'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [1, 0],
    //   translateX: [0, 100],
    // }, 400)
    .add({
      targets: document.querySelector('.services__dots'),
      easing: 'easeInQuad',
      duration: 300,
      opacity: [1, 0],
      translateX: [0, 100]
    }, 400);
  
    return servicesOutAnimations.finished;
  }else {
    showLogoOutServices();
    const servicesOutAnimations = anime.timeline({
      easing: 'easeInQuad',
    })
    .add({
      targets: item,
      duration: 800,
      // opacity: [1, 0],
    }, 700)
    // .add({
    //   targets: document.querySelector('.wrap-burger'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [1, 0],
    //   translateX: [0, 100],
    // }, 400)
    .add({
      targets: document.querySelector('.services__arrow'),
      easing: 'easeInQuad',
      duration: 300,
      opacity: [1, 0],
      translateX: [0, 100]
    }, 400);
    return servicesOutAnimations.finished;
  }
  
};
