import Highway from '@dogstudio/highway';
import { projectToContactsAnimation } from '../animations/project-contacts';
import { contactsAnimationToProject } from '../animations/project-contacts';
import { deleteNavButton, openServiceList } from '../app';
import { enableConversionsFunction } from '../app';

class contactsTransition extends Highway.Transition {
  in({ from, done }) {
    projectToContactsAnimation(from).then(function() {
      window.scrollTo(0, 0);
      deleteNavButton();
      openServiceList();
      var qwer = document.querySelectorAll('.beginAdd');
      enableConversionsFunction(qwer);
      console.log("пришли на контакты");
      done(); 
    });
  }

  out({ from, done }) {
    contactsAnimationToProject(from).then(function() { 
      window.scrollTo(0, 0);
      deleteNavButton();
      var qwer = document.querySelectorAll('.beginAdd');
      enableConversionsFunction(qwer);
      from.remove(); 
      console.log("Ушли с контактов");
      done(); 
    });
  }
}

export default contactsTransition;