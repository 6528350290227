import anime from 'animejs';
import { cancelConversionsFunction } from '../app';


export function projectToIndexAnimation(container) {  
  const shrinkAnimation = anime.timeline({
    targets: container.querySelector('img.logo'),
    translateX: [0, -100],
    opacity: [1, 0],
    duration: 400,
  }, 200).add({
    targets: container.querySelector('div.advantages__container'),
    // translateY: [0, 100],
    opacity: [1, 0],
    duration: 400,
  }, 200).add({
    targets: container.querySelector('section.start'),
    duration: 600,
    width: '100vw',
    opacity: [1, 0],
    easing: 'easeInQuad',
  }, 600)
  // .add({
  //   targets: document.querySelector('a.logo--image'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [1, 0],
  //   translateX: [0, -100],
  // }, 400).add({
  //   targets: document.querySelector('a.logo--text'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [1, 0],
  //   translateX: [0, -100],
  // }, 400).add({
  //   targets: document.querySelector('.wrap-burger'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [1, 0],
  //   translateX: [0, 100],
  // }, 400)
  .add({
    targets: document.querySelector('.nav__next'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [1, 0],
    translateX: [0, 100],
  }, 400).add({
    targets: document.querySelector('.nav__prev'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [1, 0],
    translateX: [0, -100],
  }, 400);

  return shrinkAnimation.finished;
}

// function cancelConversionsFunction(trigger) {
//   if( trigger.length == undefined) {
//     $(trigger).css({"pointer-events": "none"});
//     console.log('triggerIn', trigger);
//   } else {
//     for( var i = 0; i < trigger.length; i++ ){
//       $(trigger[i]).css({"pointer-events": "none"});
//       console.log('triggerIn', trigger[i]);
//     }
//   }
  
// }

// function enableConversionsFunction(trigger) {
//   console.log("dsgfdsfgds");
  
//   if( trigger.length == undefined) {
//     $(trigger).css({"pointer-events": "auto"});
//   } else {
//     for( var i = 0; i < trigger.length; i++ ){
//       $(trigger[i]).css({"pointer-events": "auto"});
//     }
//   }
// }

export function indexToProjectAnimationIn(container) {
  var elementNodes = container.querySelector('ul.menu').childNodes;
  var elementNodesChild = container.querySelector('.item__link');

  var asdf = container.querySelector('.item__link');
  

  var qwer = document.querySelector('body');
  // console.log('container.querySelector(".item__link"); ', asdf);
  // console.log('container.querySelectorAll(".item__link"); ', qwer);
  // console.log('container.querySelector(".item__link"); ', asdf.length);
  // console.log('container.querySelectorAll(".item__link"); ', qwer.length);
  
  // console.log('typeof(asdf)', typeof asdf);
  // console.log('typeof(qwer)', typeof qwer);
  

  const shrinkAnimation = anime.timeline({
    easing: 'linear',
  })
  .add({
    begin: cancelConversionsFunction(qwer),
    // Move text
    targets: elementNodes[1],
    duration: 1000,
    easing: 'linear',
    opacity: [0, 1],
  }, 100).add({
    // Move text
    targets: elementNodes[3],
    duration: 1000,
    easing: 'linear',
    opacity: [0, 1],
  }, 500).add({
    // Move text
    targets: elementNodes[5],
    duration: 1000,
    easing: 'linear',
    opacity: [0, 1],
  }, 1000)
  .add({
    // Move text
    targets: elementNodes[1].querySelector('div.item__text'),
    duration: 1400,
    easing: 'easeOutQuad',
    translateY: [-70, 10, 0],
    opacity: [0, 1],
  }, 700).add({
    // Move text
    targets: elementNodes[3].querySelector('div.item__text'),
    duration: 1400,
    easing: 'easeOutQuad',
    translateY: [-70, 20, 0],
    opacity: [0, 1],
  }, 1000).add({
    // Move text
    targets: elementNodes[5].querySelector('div.item__text'),
    duration: 1400,
    easing: 'easeOutQuad',
    translateY: [-70, 30, 0],
    opacity: [0, 1],
  }, 1300)
  // .add({
  //   targets: document.querySelector('a.logo--image'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [0, 1],
  //   translateX: [-100, 0],
  // }, 400).add({
  //   targets: document.querySelector('a.logo--text'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [0, 1],
  //   translateX: [-100, 0],
  // }, 400).add({
  //   targets: document.querySelector('.wrap-burger'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [0, 1],
  //   translateX: [100, 0],
  // }, 400)
  .add({
    targets: document.querySelector('.nav__next'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [0, 1],
    translateX: [100, 0],
  }, 400).add({
    targets: document.querySelector('.nav__prev'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [0, 1],
    translateX: [-100, 0],
  }, 400);

  return shrinkAnimation.finished;
};


// export function projectToIndexAnimationNext(container, lastClicked) {
//   // const element = lastClicked.closest('li.nav__item');

//   // const shrinkAnimation = anime.timeline({
//   //   easing: 'easeInQuad',
//   // }, 200).add({
//   //   targets: element,
//   //   duration: 600,
//   //   width: ['100vw', 0],
//   //   direction: 'normal',
//   // }, 400);

//   const shrinkAnimation = anime.timeline({
//       duration: 200,
//     });

//   return shrinkAnimation.finished;
// }
