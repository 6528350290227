import anime from 'animejs';
import { cancelConversionsFunction } from '../app';


export const projectToRequestAnimation = (item) => {
  const element = item.childNodes[1];
  var qwer = document.querySelector('body');
  const requestInAnimations = anime.timeline({
    easing: 'easeInQuad',
  }).add({
    begin: cancelConversionsFunction(qwer),
    targets: element,
    duration: 500,
    easing: 'linear',
    opacity: [0 , 1],
  }, 0).add({
    targets: document.querySelector('section.request'),
    duration: 500,
    easing: 'easeOutQuad',
    translateX: [-100, 0],
    opacity:  [0 , 1],
  }, 200).add({
    targets: document.querySelector('.request-title'),
    duration: 500,
    easing: 'easeOutQuad',
    translateX: [-75, 0],
    opacity:  [0 , 1],
  }, 400).add({
    targets: document.querySelector('.request-form'),
    duration: 500,
    easing: 'easeOutQuad',
    translateX:  [-50, 0],
    opacity:  [0 , 1],
  }, 600).add({
    targets: document.querySelector('.request-subject'),
    duration: 500,
    easing: 'easeOutQuad',
    opacity:  [0 , 1],
  }, 800).add({
    targets: document.querySelector('.request-subject__title'),
    duration: 500,
    easing: 'easeOutQuad',
    translateY:  [-100, 0],
    opacity:  [0 , 1],
  }, 1000).add({
    targets: document.querySelector('.request-subject__list'),
    duration: 500,
    easing: 'easeOutQuad',
    translateY:  [100, 0],
    opacity:  [0 , 1],
  }, 1100)
  // .add({
  //   targets: document.querySelector('a.logo--image'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [0, 1],
  //   translateX: [-100, 0],
  // }, 400).add({
  //   targets: document.querySelector('a.logo--text'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [0, 1],
  //   translateX: [-100, 0],
  // }, 400).add({
  //   targets: document.querySelector('.wrap-burger'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [0, 1],
  //   translateX: [100, 0],
  // }, 400)
  ;
  
  return requestInAnimations.finished;
};

export const RequestAnimationToProject = (item) => {
  var qwer = document.querySelector('body');
  const element = item.childNodes[1];  
  
  const requestOutAnimations = anime.timeline({
    easing: 'easeInQuad',
  }).add({
    // Move text
    begin: cancelConversionsFunction(qwer),
    targets: element,
    duration: 500,
    easing: 'linear',
    opacity: [1 , 0],
  }, 1100).add({
    // Move image
    targets: document.querySelector('.request-subject__list'),
    duration: 500,
    easing: 'easeOutQuad',
    translateY:  [0, 100],
    opacity:  [1 , 0],
  }, 200).add({
    // Move image
    targets: document.querySelector('.request-subject__title'),
    duration: 500,
    easing: 'easeOutQuad',
    translateY:  [0, -100],
    opacity:  [1 , 0],
  }, 400).add({
    // Move image
    targets: document.querySelector('.request-subject'),
    duration: 500,
    easing: 'easeOutQuad',
    opacity:  [1 , 0],
  }, 600).add({
    // Move image
    targets: document.querySelector('.request-form'),
    duration: 500,
    easing: 'easeOutQuad',
    translateX:  [0, -50],
    opacity:  [1 , 0],
  }, 800).add({
    // Move image
    targets: document.querySelector('.request-title'),
    duration: 500,
    easing: 'easeOutQuad',
    translateX: [0, -75],
    opacity:  [1 , 0],
  }, 1000).add({
    // Move text
    targets: document.querySelector('section.request'),
    duration: 500,
    easing: 'easeOutQuad',
    translateX: [0, -100],
    opacity:  [1 , 0],
  }, 1100)
  // .add({
  //   targets: document.querySelector('a.logo--image'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [1, 0],
  //   translateX: [0, -100],
  // }, 400).add({
  //   targets: document.querySelector('a.logo--text'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [1, 0],
  //   translateX: [0, -100],
  // }, 400).add({
  //   targets: document.querySelector('.wrap-burger'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [1, 0],
  //   translateX: [0, 100],
  // }, 400)
  ;
  
  return requestOutAnimations.finished;
};
