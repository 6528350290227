import anime from 'animejs';
import { cancelConversionsFunction } from '../app';

export const serviceIn = (item) => {
  var qwer = document.querySelector('body');
  console.log('item', item);
  console.log('serviceIn');

  // if($(document).width() > 1024) { 
  const serviceInAnimations = anime.timeline({
    easing: 'linear',
  })
  .add({
    begin: cancelConversionsFunction(qwer),
    targets: item,
    duration: 300,
    // opacity: [0 , 1],
  }, 0)
  .add({
    targets: item.querySelector('.services-page__head'),
    duration: 800,
    translateX:  [-100, 0],
    opacity:  [0, 1],
  }, 200)
  .add({
    targets: item.querySelector('.services-page__list1'),
    duration: 800,
    translateX: [50, 0],
    opacity:  [0, 1],
  }, 800)
  .add({
    targets: item.querySelector('.services-page__list2'),
    duration: 800,
    translateX: [100, 0],
    opacity:  [0, 1],
  }, 1100)
  .add({
    targets: item.querySelector('.services-page__list3'),
    duration: 800,
    translateX:  [150, 0],
    opacity:  [0 , 1],
  }, 1400)
  // Анимация органов навигации
  // .add({
  //   targets: document.querySelector('a.logo--image'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [0, 1],
  //   translateX: [-150, 0],
  // }, 400).add({
  //   targets: document.querySelector('a.logo--text'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [0, 1],
  //   translateX: [-150, 0],
  // }, 400).add({
  //   targets: document.querySelector('.wrap-burger'),
  //   easing: 'easeInQuad',
  //   duration: 300,
  //   opacity: [0, 1],
  //   translateX: [100, 0],
  // }, 400)
  .add({
    targets: document.querySelector('.nav__next'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [0, 1],
    translateX: [100, 0],
  }, 400).add({
    targets: document.querySelector('.nav__prev'),
    easing: 'easeInQuad',
    duration: 300,
    opacity: [0, 1],
    translateX: [-100, 0],
  }, 400)
  .add({
    targets: document.querySelector('.services__dots'),
    easing: 'easeInQuad',
    duration: 0,
    opacity: 0,
    translateX: 100,
  });

  return serviceInAnimations.finished;
  
};

export const serviceOut = (item) => {
  if ($('.services-page-wrap').length > 0) {
    console.log(servicePageWrap);
    console.log('serviceOut');
    var servicePageWrap = document.querySelector('.services-page-wrap');
  }
    console.log(servicePageWrap);
  // if($(document).width() > 1024) {
    const serviceOutAnimations = anime.timeline({
      easing: 'linear',
    })
    .add({
      targets: item,
      duration: 300,
      // opacity: [1, 0],
    }, 2200)
    .add({
      targets: item.querySelector('.services-page__head'),
      duration: 800,
      translateX:  [0, -100],
      opacity: [1, 0],
    }, 1200)
    .add({
      targets: item.querySelector('.services-page__list3'),
      duration: 800,
      translateX:  [0, -150],
      opacity: [1, 0],
    }, 600)
    .add({
      targets: item.querySelector('.services-page__list2'),
      duration: 800,
      translateX:  [0, -100],
      opacity: [1, 0],
    }, 300)
    .add({
      targets: item.querySelector('.services-page__list1'),
      duration: 800,
      translateX:  [0, -50],
      opacity: [1, 0],
    }, 0)
    .add({
      targets: servicePageWrap,
      duration: 800,
      translateX:  [0, 150],
      opacity: [1, 0],
    }, 200)
  
    // Анимация органов навигации
    // .add({
    //   targets: document.querySelector('a.logo--image'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [1, 0],
    //   translateX: [0, -150],
    // }, 400).add({
    //   targets: document.querySelector('a.logo--text'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [1, 0],
    //   translateX: [0, -150],
    // }, 400)
    // .add({
    //   targets: document.querySelector('.wrap-burger'),
    //   easing: 'easeInQuad',
    //   duration: 300,
    //   opacity: [1, 0],
    //   translateX: [0, 100],
    // }, 400)
    .add({
      targets: document.querySelector('.nav__next'),
      easing: 'easeInQuad',
      duration: 300,
      opacity: [1, 0],
      translateX: [0, 100],
    }, 400).add({
      targets: document.querySelector('.nav__prev'),
      easing: 'easeInQuad',
      duration: 300,
      opacity: [1, 0],
      translateX: [0, -100],
    }, 400).add({
      targets: document.querySelector('.services__dots'),
      easing: 'easeInQuad',
      duration: 300,
      opacity: [0, 1],
      translateX: [0, 100]
    }, 400);
  
    return serviceOutAnimations.finished;
  
  
};
