import Highway from '@dogstudio/highway';
import { projectToAStaffAnimation } from '../animations/project-aStaff';
import { aStaffAnimationToProject } from '../animations/project-aStaff';
import { horizontallScroll, verticalScrollTop, openServiceList } from '../app';
import { enableConversionsFunction } from '../app';

class aStaffTransition extends Highway.Transition {
  in({ from, done }) {
    projectToAStaffAnimation(from).then(function() { 
      window.scrollTo(0,0);
      horizontallScroll();
      verticalScrollTop();
      openServiceList();
      var qwer = document.querySelectorAll('.beginAdd');
      enableConversionsFunction(qwer);
      console.log("Пришли на о персонале");
      done(); 
    });
  }

  out({ from, done }) {
    aStaffAnimationToProject(from).then(function() { 
      window.scrollTo(0,0);
      horizontallScroll();
      verticalScrollTop();
      var qwer = document.querySelectorAll('.beginAdd');
      enableConversionsFunction(qwer);
      from.remove();
      console.log("Ушли с о персонале");
      done(); 
    });
  }
}

export default aStaffTransition;
